/* TODO: SPLIT UP INTO MULTIPLE FILES DEPENDING ON WHAT REACT COMPONENT THEY GO WITH */

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}

body {
    background: linear-gradient(-90deg, #02203c, #001528);
}


::-webkit-scrollbar {
    width: 8px;

    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #457b9d;

    border-radius: 10px;
}



.container {
    display: flex;
    flex-direction: column;

    justify-content: space-evenly;

    height: 100%;
}

.container-header {
    min-height: 70px;
}

.logo {
    margin: 0;
    padding: 0;

    font-family: "Courier New", monospace;
    text-align: center;
    font-size: 50px;

    color: #fff;
}

.container-footer {}

.container-body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    gap: 20px;
    margin: 0 50px;
}

.slides-container {
    width: 100%;
}




.slide-footer-container {
    position: relative;
}

.navigation-container {
    display: flex;
    justify-content: center;
}

.navigation-button {
    padding: 8px;
    margin: 5px 20px 0px;

    color: #FFFDD0;

    font-size: 2em;
    font-weight: bold;
    letter-spacing: 5px;

    transition: transform 0.3s ease;

    background-color: #FF6F00;
    /* Vibrant Orange -- Scratch Theme */
    /*background-color: #FFA07A; Soft Orange -- Less Intense than Vibrant Orange */

    border-radius: 12px;
}
.navigation-button:hover {
    cursor: pointer;

    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.75);

    transform: scale(1.05);
}

.navigation-button.disabled {
  background-color: #D9A084;
}

.presentation-control-button {
    background-color: #55AA55;
}

.slide-number-input-container {
    display: flex;

    position: absolute;
    right: 0;
    top: 50%;

    transform: translateY(-50%);

    height: 35px;
}

.slide-number-input {
    width: 105px;

    margin: 2px;

    border-radius: 12px;
    text-align: center;
}

.slide-number-input::-webkit-outer-spin-button,
.slide-number-input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.slide-number-input-button {
    background-color: #ff6f00;

    width: 30%;

    margin: 0;
    padding: 4px;

    text-align: center;

    font-size: 22px;
    letter-spacing: 2px;
}




.slide-image-container {
    position: relative;

    /*
    width: 100vh;
    */
}

.slide-image {
    /* TODO: DELETE - TEMP SAVE for if want revert back to previous layout
    width: 100%; 
    max-height: 70vh;
    */
    min-width: 100%;
    max-height: 70vh;

    border-radius: 20px;
}

.slide-image:hover {
    cursor: pointer;
}

.slide-number {
    position: absolute;

    right: 10px;
    top: 5px;

    color: red;

    font-size: 2rem;
    font-weight: 700;
}


/* TODO: Look more deeply into -- Was result of testing, unclear if all is needed */
.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*... background: rgba(0, 0, 0, 0.5); /* Half-transparent black */

    pointer-events: none;
    /* This prevent overlay from intercepting click events */

    border-radius: 20px;
}

.hide {
    display: none;
}

.overlay i.fas {
    font-size: 4rem;
    /*... color: #fff; */
    color: #4f4f4f;

    transition: all 0.3s ease;
}

/* ==============================================================================  */



.chat-container,
.lecture-container {
    display: flex;
    flex-direction: column;

    /*flex-basis: 30%;
    min-width: 30%;*/
    width: 80%;
    height: 78vh;

    border-left: 1px solid #000;
    border-radius: 20px;

    padding: 10px;
    overflow: hidden;

    background-color: #fff;
}

.chat-header,
.lecture-header {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.45rem;
}

.report-bug {
    float: right;

    width: 20px;
    height: 20px;

    padding: 0 10px;

    border-radius: 50%;
}

.report-bug:hover {
    cursor: pointer;
}

.chat-messages,
.lecture-messages {
    min-height: 2.25rem;
    /*margin: .9375rem;*/
    margin: 1rem;
    margin-right: 0;

    height: 90%;
    overflow: auto;

    word-wrap: break-word;

    font-family: system-ui;
    /* TODO: Change ?? Consistent? */
}

.chat-message {
    display: flex;

    margin: 20px 0;
}

.chat-message_profile {}

.chat-message_profile_image {
    height: 50px;
    width: 50px;

    margin-right: 10px;

    border-radius: 50%;
}

.chat-message_profile_name {
    /*align-self: center;*/
    font-size: 1.2em;
}

.chat-message_text {
    margin: 0;
}

.highlight {
    background-color: yellow;
}


.chat-input-container {
    display: flex;

    flex-direction: row;
    align-items: center;

    width: 100%;

    padding: 5px;

    border-radius: 25px;

    background-color: rgb(241, 243, 244);
}

.chat-input-container:focus {
    border: none;
}

.chat-input_text {
    display: inline-flex;
    flex-direction: column;

    flex-grow: 1;
    height: 1rem;

    padding: 0 1em 0 1em;
    margin: 8px 0 8px 0;

    position: relative;

    resize: none;

    font-size: 1em;

    border: none;
    outline: none;

    background: transparent;
}

.chat-input_button {
    width: 30px;
    padding: 0 10px;
}

.chat-input_button:hover {
    cursor: pointer;
}


.microphone {
    width: 40px;
    height: 40px;

    font-size: 18px;

    border-radius: 50%;

    background-color: limegreen;
}

.microphone.muted {
    background-color: red;
}

.microphone:hover {
    cursor: pointer;

    border: 3px solid black;
}


.file-upload-icon {
    position: relative;

    background: none;
    border: none;

    cursor: pointer;

    margin-left: 7px;
    ;
}

.file-attachment-info {
    margin-top: 5px;
    padding: 5px 10px;

    border: 1px solid #ddd;
    border-radius: 25px;

    background-color: #f9f9f9;

    color: #333;

    font-size: 12px;
    text-align: center;
}

.count-badge {
    display: flex;
    align-items: center;

    position: absolute;

    top: -5px;
    right: -5px;

    padding: 2px 4px;

    background-color: red;
    color: white;

    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
}

.remove-badge {
    display: flex;
    align-items: center;

    position: absolute;

    top: -5px;
    left: -4px;

    padding: 2px 4px;

    background-color: black;
    color: white;

    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
}

/* Keyword Buttons */
.keyword-button {
    background-color: #fff;
    border: 2px solid #0000;
    border-radius: 24px;
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px 0 #00000024, 0 1px 18px 0 #0000001f;
    cursor: pointer;
    font-size: 14px;
    height: 48px;
    letter-spacing: .25px;
    margin: 5px 10px 0;
    padding: 2px 10px;
}

.keyword-button:hover {
    background: #F6F9FE;
    color: #174ea6;
}

.keyword-button:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
}

.keyword-button:hover {
    outline: none;
    border: 2px solid #4285f4 !important;
}


/* TIMER */
#slideTimerDisplay {
    position: absolute;
    display: none;

    color: white;

    font-size: 3rem;
    font-weight: bold;

    right: 0;
    bottom: 0;
    padding-right: 15px;
}


/* NOTIFICATION SYSTEM  -- TODO: REVISIT */
#container-notifications {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    padding: 20px;
}

.notification {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    border: 2px solid black;
    border-radius: 10px;
}

.slide-down {
    animation: slide-down 0.3s forwards;
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}