

.admin-toggle {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 40;
}

.admin-toggle-button {
  background-color: #e63946;
  padding: 0.75rem;
  border-radius: 50%;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(230, 57, 70, 0.3);
  transition: all 0.3s ease;
  font-size: 1.25rem;
  border: none;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-toggle-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(230, 57, 70, 0.4);
  background-color: #dc2f3c;
}

.admin-tooltip {
  position: absolute;
  left: 100%;
  margin-left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1d3557;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.overlay-container {
  position: fixed;
  inset: 0;
  background-color: rgba(29, 53, 87, 0.6);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.admin-panel-container {
  position: relative;
  width: 90%;
  max-width: 1400px;
  max-height: 90vh;
}

.admin-close-button {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background-color: #e63946;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  z-index: 2;
}

.admin-close-button:hover {
  cursor: pointer;
  background-color: #dc2f3c;
  transform: scale(1.1);
}

.admin-panel {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 80vh;
  display: flex;
  overflow: hidden;
}

.admin-sidebar {
  width: 280px;
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-right: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  cursor: pointer;
}

.admin-sidebar-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #1d3557;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e9ecef;
}

.admin-sidebar-button {
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: none;
  background: none;
  color: #495057;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-sidebar-button:hover {
  cursor: pointer;
  background-color: #e9ecef;
  color: #1d3557;
}

.admin-sidebar-button-active {
  background-color: #457b9d;
  color: #ffffff;
}

.admin-sidebar-button-active:hover {
  background-color: #1d3557;
  color: #ffffff;
}

.admin-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  background-color: #ffffff;
}

.admin-content-title {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #1d3557;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e9ecef;
}

.admin-content-description {
  color: #666;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  line-height: 1.5;
}

.admin-content-section {
  padding: 1rem;
}



.admin-input-container {
  margin-bottom: 2rem;
  max-width: 100%;
}

.admin-input-label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #495057;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
}

.admin-input-description {
  display: block;
  font-size: 0.8rem;
  font-weight: normal;
  color: #666;
  margin-top: 0.25rem;
}


.admin-input-textarea {
  width: calc(100% - 2rem);
  height: 8rem;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  background-color: #f8f9fa;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #212529;
  transition: all 0.2s ease;
  /*resize: vertical;*/
  resize: none;
  font-family: inherit;
}

.admin-input-textarea:focus {
  outline: none;
  border-color: #457b9d;
  background-color: #ffffff;
  box-shadow: 0 0 0 4px rgba(69, 123, 157, 0.1);
}

.admin-input-text {
  flex: 1;
  padding: 0.5rem;
  border: 2px solid #e9ecef;
  border-radius: 6px;
  font-size: 0.9rem;
}




.admin-list-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-list-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}




.admin-button-remove {
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #fee2e2;
  color: #dc2626;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-button-add {
  padding: 0.75rem;
  border-radius: 6px;
  background-color: #f3f4f6;
  color: #374151;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  font-weight: 500;
}

.admin-button-add:hover {
  background-color: #e5e7eb;
}




/* Sidebar Section Navigation */
.admin-section-selector {
    margin-bottom: 1.5rem;
    position: relative;
}

.admin-section-dropdown {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #1d3557;
    background-color: #ffffff;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
}

.admin-section-selector::after {
    content: '▾';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #1d3557;
    pointer-events: none;
}

.admin-section-dropdown:hover {
    border-color: #457b9d;
}

.admin-section-dropdown:focus {
    outline: none;
    border-color: #457b9d;
    box-shadow: 0 0 0 4px rgba(69, 123, 157, 0.1);
}

.admin-subsection-nav {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    overflow-y: auto;
  }



/* TODO: Decide if want keep custom scrollbar or not, as blue may look weird? */
.admin-content::-webkit-scrollbar {
    width: 8px;
}

.admin-content::-webkit-scrollbar-track {
    background: #f1f3f5;
    border-radius: 4px;
}

.admin-content::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 4px;
}

.admin-content::-webkit-scrollbar-thumb:hover {
    background: #868e96;
}



/* Tooltip */
.tooltip-container {
    display: inline-block;
    position: relative;
}

.info-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    margin-left: 0.5rem;
}

.tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 0.5rem;
    border-radius: 4px;
    position: absolute;
    white-space: pre-wrap;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;

    width: 800px;
    overflow: auto;
    max-height: 400px;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
}



.prompt-execution-container {
  display: inline;
}

.prompt-execution-button {
  padding: 5px 10px;
  margin: 0;

  color: #fff;
  font-size: 0.9em;
  font-weight: 600;

  background-color: #007bff;
  /* Subtle blue for admin-style button */
  border: none;
  border-radius: 4px;

  transition: background-color 0.2s ease, transform 0.2s ease;
}

.prompt-execution-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
  transform: translateY(-1px);
  /* Slight lift effect */
  cursor: pointer;
}

.prompt-execution-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.info-icon-button {
  background-color: transparent;
  border: none;
  font-size: 1em;
  color: #007bff;
  cursor: pointer;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: color 0.2s ease, transform 0.2s ease;
}

.info-icon-button:hover {
  color: #0056b3;
  transform: scale(1.1);
}


